/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.SearchOverlay {
    &-Results {
        @include desktop {
            position: fixed;
            padding-block-start: 0;
            inset-block-start: 99px;
            inset-inline-start: 0;
            padding-inline-start: 0;
            height: 100%;
            background: rgba($color: #000000, $alpha: 0.5);
        }


        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            max-height: none;

            .SearchItem {
                margin: 0;
                
                @include desktop {
                    flex-basis: calc(25% - 18px);
                }

                @include mobile {
                    flex-basis: calc(50% - 12px);
                }

                &-Link {
                    display: block;
                }

                &-Wrapper {
                    display: block;

                    .Image {
                        padding-block-end: 0;

                        &-Image {
                            position: relative;
                            display: block;
                        }
                    }
                }

                &-Title {
                    font-weight: 600;
                    font-size: 12px;

                    @include desktop {
                        margin-block-start: 20px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &-Main {
        margin: auto;
        max-width: var(--content-wrapper-width);
        background-color: $white;
        

        @include desktop {
            padding: 30px;
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        margin-block-end: 22px;
        
        @include mobile {
            margin-block-end: 15px;
        }

        .see_all_results {
            font-weight: 600;
            // letter-spacing: 0.2px;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
        }

        span {
            font-size: 14px;
        }
    }

    &-Noresults {
        padding: 30px;

        p {
            text-align: center;
            font-weight: 700;
            font-size: 20px;
        }
    }
}
